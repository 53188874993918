import { Box } from '@material-ui/core'
import { StaticScreenWrapper } from 'common/HOCs/StaticScreenWrapper'
import { TeaserFooter } from 'components/TeaserFooter'
import { ImageProps, ImageSlider } from 'components/sliders/ImageSlider'
import { TeaserContext } from 'context/TeaserContext'
import { WINDOW_TYPES } from 'context/const'
import { PropertyInterface } from 'interfaces/teaser.interface'
import Error from 'pages/Error/Error'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchPropertyById } from 'services/property'
import { cleanWebViewData, handleWebViewData } from 'utils/handleWebViewData'
import { TeaserPreviewHandler } from '../TeaserScreen/components/TeaserPreviewHandler'
import { ContainerDetailsBroker } from './components/ContainerDetails'
import { errorText } from './const'
import { useStyles } from './styles'

export const TeaserProperty = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { teaserId, id } = useParams()
  const { teaser, activeWindow } = useContext(TeaserContext)
  const [webViewData, setWebViewData] = useState<undefined | string>(undefined)
  const [propertyImages, setPropertyImages] = useState<ImageProps[] | undefined>(undefined)
  const [estateObject, setEstateObject] = useState<undefined | PropertyInterface>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<undefined | string>(undefined)

  const setProperty = async (): Promise<void> => {
    // if (!webViewData || !id) {
    //   return
    // }
    setIsLoading(true)
    const response = await fetchPropertyById(id)
    if (typeof response !== 'string') {
      setEstateObject(response)
      setPropertyImages(response?.images)
    } else {
      setError(response)
    }
    setIsLoading(false)
  }

  const generateImageArray = (property?: PropertyInterface) => {
    let images: ImageProps[] = []
    if (property?.mainImage) {
      images.push(property?.mainImage)
    }

    if (property?.images) {
      // Remove duplicates
      const newImages = property?.images.filter(i => i.imageUrl !== property?.mainImage?.imageUrl)
      images = images.concat(newImages)
    }

    return images
  }

  useEffect(() => {
    !teaser && handleWebViewData(webViewData, setWebViewData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (teaser) {
      const currentProperty = teaser.properties.find(property => property.id.toString() === id)
      const images = generateImageArray(currentProperty)
      setPropertyImages(images)
      setEstateObject(currentProperty)
    } else {
      navigate(`/teasers/${teaserId}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teaser, id])

  useEffect(() => {
    if (!webViewData || !id) {
      return
    }
    cleanWebViewData()
    setProperty()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, webViewData])

  if (!estateObject && !teaser) return <Error message={error || errorText} />
  if (isLoading || !estateObject) return <></>

  return error ? (
    <Error message={error || errorText} />
  ) : (
    <StaticScreenWrapper>
      {activeWindow !== WINDOW_TYPES.UNTOUCHED && teaser?.isDraft && (
        <Box className={classes.previewWrapper}>
          <TeaserPreviewHandler />
        </Box>
      )}
      <Box className={`${teaser?.isDraft ? classes.previewTopMargin : ''}`}>
        <ImageSlider images={propertyImages} isDraft={teaser?.isDraft ?? false} />
        <ContainerDetailsBroker
          isWebView={!!webViewData}
          isDraft={teaser?.isDraft ?? false}
          estateObject={estateObject}
        />
      </Box>
      <TeaserFooter wasSigned={false} />
    </StaticScreenWrapper>
  )
}

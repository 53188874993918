import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    container: {
      height: '100%',
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inner: {
      padding: '0 40px',
    },
    image: {
      width: '100%',
    },
    title: {
      fontSize: '18px',
    },
    text: {
      textAlign: 'center',
      margin: '40px auto 0',
      maxWidth: '330px',
    },
    closeButton: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '20px',
      backgroundColor: '#00000010',
      width: '25px',
      height: '25px',
      padding: '4px',
      left: '15px',
      top: '15px',
    },
  }
})

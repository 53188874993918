import parsePhoneNumber from 'libphonenumber-js'
import { Box } from '@material-ui/core'
import { useStyles } from '../../styles'
import { CustomTypography } from 'components/Typography'
import { DETAILS } from '../../enums'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { TeaserBrokerageDetailsType } from '../TeaserBrokerageDetails'

interface OrderDetailsProps extends TeaserBrokerageDetailsType {
  agreementId: number
  agreementType: string
  agreementTitle: string
  exclusivity?: boolean
  signeeFullName: string
  signeeNationalId: string
  signeeAddress: string
  signeePhoneNumber: string
  isDraft: boolean
  isSigned: boolean
}

export const TeaserOrderDetails: React.FC<OrderDetailsProps> = ({
  agreementId,
  agreementTitle,
  signeeFullName,
  signeeNationalId,
  signeeAddress,
  signeePhoneNumber,
  isDraft,
  isSigned,
}) => {
  const styles = useStyles()

  const formatPhoneNumber = (phoneNumberStr?: string) => {
    if (!phoneNumberStr || phoneNumberStr.length === 0) {
      return '-'
    }

    const phoneNumber = parsePhoneNumber(phoneNumberStr)
    return phoneNumber?.formatNational()
  }

  return (
    <Box className={styles.box}>
      <CustomTypography component={FONTS_TAG_TYPE.H2} type={FONTS.H2_BLACK_18} value={agreementTitle} />
      {isDraft ? null : (
        <>
          <CustomTypography
            component={FONTS_TAG_TYPE.H4}
            type={FONTS.H4_PINK_60}
            value={`${DETAILS.AGREEMENT} ${agreementId}`}
          />
          {isSigned ? (
            <>
              <br />
              <CustomTypography
                component={FONTS_TAG_TYPE.H4}
                type={FONTS.H4_BLACK_REGULAR}
                value={`${DETAILS.CLIENT_NAME}: ${signeeFullName}`}
              />
              <CustomTypography
                component={FONTS_TAG_TYPE.H4}
                type={FONTS.H4_BLACK_REGULAR}
                value={`${DETAILS.CLIENT_PASSPORT_NUMBER}: ${signeeNationalId}`}
              />
              <CustomTypography
                component={FONTS_TAG_TYPE.H4}
                type={FONTS.H4_BLACK_REGULAR}
                value={`${DETAILS.CLIENT_ADDRESS}: ${signeeAddress}`}
              />
              <CustomTypography
                component={FONTS_TAG_TYPE.H4}
                type={FONTS.H4_BLACK_REGULAR}
                value={`${DETAILS.CLIENT_PHONE_NUMBER}: ${formatPhoneNumber(signeePhoneNumber)}`}
              />
            </>
          ) : null}
        </>
      )}
    </Box>
  )
}

import { makeStyles } from '@material-ui/core'
import { imageSliderHeight } from './const'

export const useStyles = makeStyles(() => {
  return {
    mainBox: {
      position: 'relative',
      marginTop: imageSliderHeight,
      paddingTop: '40px',
      borderRadius: '30px 30px 0 0',
      backgroundColor: 'white',
    },
    mainBoxForDraft: {
      position: 'relative',
      marginTop: '375px',
      paddingTop: '40px',
      borderRadius: '30px 30px 0 0',
      backgroundColor: 'white',
    },
    loanConfirmationBox: {
      overflow: 'hidden',
      marginTop: '180px'
    },
    submittedFormTitle: {
      color: '#FF335B',
      opacity: .9,
      fontWeight: 700,
      fontSize: 18,
      textAlign: 'center',
      marginBottom: 20,
    },
    submittedFormWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  }
})

import React, { useEffect } from 'react'
import { FormControl, FormHelperText, TextField } from '@material-ui/core'
import { useStyles } from './styles'
import { INPUT_LABELS } from './const'
import { CustomButton } from '../buttons/Button'
import { LOAN_TEXTS } from 'pages/LoanScreen/const'
import { ValidatedData } from '../../pages/LoanScreen/types'

interface ILoanButton {
  isActive: boolean
  onClick: () => void
}

interface ILoanInput {
  value: string
  onChange: (e: any) => void
  validateData: (type: ValidatedData) => void
  error?: string
}

export const PhoneInput = ({ value, onChange, validateData, error }: ILoanInput) => {
  const styles = useStyles()

  useEffect(() => {
    value.length && validateData('phone')
  }, [value])

  return (
    <FormControl required error={!!error} className={styles.phoneInputWrapper}>
      <TextField
        inputProps={{ inputMode: 'tel' }}
        className={`${styles.generalStyles} ${styles.customLabel} ${styles.phoneInput}`}
        variant='outlined'
        type='number'
        value={value}
        label={INPUT_LABELS.PHONE}
        onChange={onChange}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export const NameInput = ({ value, onChange, validateData, error }: ILoanInput) => {
  const styles = useStyles()

  useEffect(() => {
    value.length && validateData('name')
  }, [value])

  return (
    <FormControl required error={!!error} className={styles.fullWidth}>
      <TextField
        className={`${styles.generalStyles} ${styles.customLabel} ${styles.fullWidth} ${styles.additionalLabelSpacings}`}
        variant='outlined'
        type='text'
        value={value}
        label={INPUT_LABELS.NAME}
        onChange={onChange}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export const LoanSubmitButton = ({isActive, onClick}: ILoanButton) => {
  return (
    <FormControl fullWidth disabled={!isActive}>
      <CustomButton isActive={isActive} title={LOAN_TEXTS.PROCEED_BUTTON} onSign={onClick} />
    </FormControl>
  )
}
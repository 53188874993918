import { makeStyles } from '@material-ui/core'
import { COLORS } from 'common/enums/colors'
import { SHADOW } from 'common/enums/shadow'

export const useStyles = makeStyles(() => {
  const BANNER_BG_COLOR = '#EFEFEF'
  const BANNER_BORDER = '1px solid #C0C0C0'

  return {
    banner: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
    },
    topBanner: {
      zIndex: 99,
      left: 0,
      right: 0,
      top: 0,
      position: 'fixed',
      padding: '20px 16px',
      background: BANNER_BG_COLOR,
      borderBottom: BANNER_BORDER,
    },
    topBannerInner: {
      maxWidth: '360px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0 auto',
    },
    topBannerLogo: {
      width: '50px',
      display: 'inline-block',
    },
    topBannerTexts: {
      marginInlineStart: '12px',
      marginInlineEnd: '12px',
    },
    topBannerButton: {
      fontFamily: "'Noto Sans Hebrew', 'Helvetica', 'Noto Sans', 'Roboto', 'Arial', sans-serif !important",
      backgroundColor: `${COLORS.PINK} !important`,
      color: COLORS.WHITE,
      maxWidth: 80,
      height: 0,
      padding: '20px',
      boxShadow: `${SHADOW.SHADOW_BOX} !important`,
    },
  }
})

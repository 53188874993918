import { CommissionModel, CommissionType } from './types'

export const ContractDetails: string[] = [
  'הנני מצהיר/ה כי פרטי הזהות שמסרתי לנציג אייקורט הנם נכונים וחתימתי מטה מאשרת את אמינותם',
  'הנני מצהיר/ה בזאת כי פניתי לנציג אייקורט על מנת שיפעל עבורי למציאת נכס- לקנייה.',
  ' אני מתחייב/ת לשלם דמי תיווך בהתאם לתעריף, המפורט מטה, וזאת מייד לאחר חתימת הסכם מחייב בין הצד השני לעסקה לביני או מי מטעמי, או ממשפחתי, או כל אדם או גוף אחר שאני קשור אליהם במישרין או בעקיפין.',
  ' הנני מתחייב/ת לעדכן את נציג אייקורט או הנהלת המשרד במידה ואני מייצג/ת גורם כלשהו אחר המתעניין בנכסים שיוצגו לי. ',
  ' הנני מתחייב שלא אמסור לכל צד שלישי כל שהוא ושאינו נוטל חלק בביצוע העסקה כל מידע שאקבל מכם. אין בהפרת התחייבות זו כדי לשלול מכם את דמי התיווך המגיעים או שהגיעו לכם ממני .',
  ' הנני מצהיר/ה כי חתימתי מטה בסמוך לכתובות המצוינות מאשרת כי הנכס או המידע לגביו הוצגו בפניי ע”י נציג אייקורט.',
  ' הנני מצהיר כי הודגש בפניי ע”י נציג אייקורט כי תעריף דמי התיווך קבוע ואינו נתון לשינוי או מו”מ. אם הפרתי התחייבותי בהתאם למסמך זה, הריני מתחייב/ת לפצותכם.',
  ' על כל נזק, הפסד והוצאות שייגרמו לכם כתוצאה מההפרה כאמור, וזאת בנוסף לתשלום דמי התיווך המגיעים לכם.',
  ' יובהר בזאת כי התנאים לביצוע העסקה בגינה ישולמו דמי תיווך, יסוכמו באופן סופי באמצעות עורכי הדין המייצגים את הצדדים וחתימה על הסכם מכר/שכירות מחייב. כל מצג או מו”מ מוקדם בו הוצגו תנאים לבצוע העסקה על ידי אייקורט ו/או נציגיה יאושרו על ידי הצדדים באמצעות עורכי דינם. לחתומים מטה לא יהיו כל טענות כלפי אייקורט ו/או נציגיה בכל הקשור להמלצתם לעניין שווי הנכס והמחיר שישלמו עבורו. לחתומים מטה לא יהיו כל טענות ו/או דרישות מאייקורט ו/או נציגיה לאחר חתימה על הסכם המכר/שכירות בכל הקשור בתנאים לבצוע העסק ו/או דמי התיווך אותם יידרשו לשלם בגינה.',
]

export const ComisionDetails: string[] = [
  ' דמי התיווך ישולמו במלואם במעמד חתימת הסכם מחייב, עמלתכם תשולם גם אם הנכס יימכר במחיר אחר מהמחיר המבוקש המצוין לעיל.',
  'איחור בתשלום יחויב בריבית חריגה כמקובל בבנק הבינלאומי',
  ' לדמי התיווך יש להוסיף מע”מ כחוק.',
]

export const mockCommission: CommissionModel = { sum: 0, type: CommissionType.SUM, percentage: 0, months: 0 }

export enum PREVIEW_TYPE {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
  UNSET = '',
}

import { Box } from '@material-ui/core'
import download_icon from 'assets/images/actions/download.svg'
import open_icon from 'assets/images/actions/open.svg'
import see_icon from 'assets/images/actions/see.svg'
import whatsapp_icon from 'assets/images/actions/whatsapp.svg'
import eldar_icon from 'assets/images/eldar.png'
import { FONTS } from 'common/enums/fonts'
import { LinkCard } from 'components/LinkCard/LinkCard'
import { CustomTypography } from 'components/Typography'
import { Wrapper } from 'components/containers/Wrapper'
import { TeaserContext } from 'context/TeaserContext'
import { TeaserPreviewHandler } from 'pages/TeaserScreen/components/TeaserPreviewHandler'
import { AGREEMENT_TYPE } from 'pages/TeaserScreen/enums'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { updateOrCreateLead } from 'services/teaser'
import { BottomBanner } from './BottomBanner'
import { LeadConfirmation } from './LeadConfirmation'
import { TopBanner } from './TopBanner'
import { useStyles } from './styles'

export const TeaserConfirmation = () => {
  const { teaser } = useContext(TeaserContext)
  const [showLeadConfirmation, setShowLeadConfirmation] = useState(false)
  const navigate = useNavigate()
  const { teaserId } = useParams()
  const classes = useStyles()

  // TODO: Remove
  // const [signedDate, setSignedDate] = useState<string>('')
  // const [agentData, setAgentData] = useState<IPerson | undefined>(undefined)

  useEffect(() => {
    if (!teaser || !teaser.properties.length) {
      navigate(`/teasers/${teaserId}`)
      return
    }

    // TODO: Remove
    // setSignedDate(transformSignDate(teaser.agreement.signedAt, DATE_TYPES.PROPERTY))
    // setAgentData(teaser.agent)
  }, [navigate, teaser, teaserId])

  // TODO: Remove
  // const formatAddress = (address: IAddress) => {
  //   const { street, apartment, number, city } = address
  //   if (apartment) {
  //     return `${street} ${number}/${apartment}, ${city}`
  //   } else {
  //     return `${street} ${number}, ${city}`
  //   }
  // }

  const leadTracking = (leadInfo: {
    clickedToSite?: boolean
    clickedLoanRequest?: boolean
    clickedWhatsApp?: boolean
    clickedAppStore?: boolean
    clickedGooglePlay?: boolean
  }) => {
    if (teaser?.agreement?.id) {
      updateOrCreateLead({
        agreementId: teaser?.agreement.id,
        ...leadInfo,
      })
    }
  }

  const eldarClicked = () => {
    leadTracking({ clickedLoanRequest: true })

    setShowLeadConfirmation(true)
  }

  const navigateToTeaserSigned = () => {
    navigate(`/teasers/${teaser?.id}/signed`)
  }

  const navigateToProperty = () => {
    if (teaser?.showPropertyFullProfile && teaser?.agreement.type !== AGREEMENT_TYPE.PROPERTY_OWNER) {
      navigate(`/teasers/${teaser?.id}/properties/${teaser.properties[0].id}`)
      return
    }
  }

  // TODO: Remove
  // const getContractInfoText = () => {
  //   if (teaser?.properties.length === 1) {
  //     return formatAddress(teaser?.properties[0].address)
  //   }

  //   return `הסכם זה מכיל ${teaser?.properties.length} נכסים`
  // }

  return (
    <>
      {teaser?.isDraft && (
        <Box className={classes.previewWrapper}>
          <TeaserPreviewHandler />
        </Box>
      )}
      <Wrapper>
        <Box className={`${classes.leadConfirmation} ${showLeadConfirmation ? '' : classes.leadConfirmationHide}`}>
          <LeadConfirmation
            onClose={() => {
              setShowLeadConfirmation(false)
            }}
          />
        </Box>
        <Box className={teaser?.isDraft ? classes.previewTopMargin : ''}>
          <TopBanner
            onSiteClicked={() => {
              leadTracking({ clickedToSite: true })
            }}
          />
          <Box className={classes.actionsContainer}>
            {teaser?.showPropertyFullProfile && (
              <LinkCard
                title="לפרופיל הנכס"
                subtitle="כל המידע במקום אחד!"
                onClick={navigateToProperty}
                icon={<img src={open_icon} alt="Open" />}
              />
            )}
            <LinkCard
              title="לצפייה והורדת ההסכם"
              subtitle="נחתם דיגיטלית באמצעות טיזר"
              icon={
                <Box style={{ display: 'flex', gap: '12px', alignItems: 'start' }}>
                  <img src={see_icon} onClick={navigateToTeaserSigned} alt="See" />
                  <a href={teaser?.agreement.fileUrl || ''}>
                    <img src={download_icon} alt="Download" />
                  </a>
                </Box>
              }
              // onClick={navigateToTeaserSigned}
            />
            {/* <LinkCard
              title="להורדת ההסכם"
              subtitle="נחתם דיגיטלית באמצעות טיזר"
              icon={<img src={download_icon} alt="Download" />}
              link={teaser?.agreement.fileUrl || ''}
            /> */}
            <Box className={classes.eldarContainer} onClick={eldarClicked}>
              <Box className={classes.eldarLogoContainer}>
                <img src={eldar_icon} alt="Eldar" className={classes.eldarLogo} />
              </Box>
              <Box className={classes.eldarTextContainer}>
                <Box>
                  <CustomTypography value={'משכנתה בקליק!'} type={FONTS.H4_BLACK_80} component="h4" />
                  <CustomTypography value={'לשיחת ייעוץ ללא עלות'} type={FONTS.H5_BLACK_60} component="h5" />
                </Box>
                {/* <img src={phone_icon} alt="Eldar" /> */}
              </Box>
            </Box>
            <LinkCard
              title="לצ׳אט ב-וואטסאפ"
              onClick={() => {
                leadTracking({ clickedWhatsApp: true })
              }}
              link={'https://wa.me/97233763985'}
              subtitle="הנציגים שלנו זמינים עבורך בכל שאלה"
              icon={<img src={whatsapp_icon} alt="WhatsApp" />}
            />
            {/* <ContractInfo
              extraMargin
              link={teaser?.agreement.fileUrl || ''}
              date={signedDate}
              text={getContractInfoText()}
            /> */}
          </Box>
          <BottomBanner
            onAppStoreClicked={() => {
              leadTracking({ clickedAppStore: true })
            }}
            onGooglePlayClicked={() => {
              leadTracking({ clickedGooglePlay: true })
            }}
          />
        </Box>
      </Wrapper>
    </>
  )
}

import { defaultLoanState, INPUT_ERRORS, phoneRegex } from './const'
import { atom, useRecoilState } from 'recoil'
import { ValidatedData } from './types'

const loanState = atom({
  key: 'Loan',
  default: defaultLoanState,
})

export const useLoan = () => {
  const [loanData, setLoanData] = useRecoilState(loanState)

  const validateData = (type: ValidatedData) => setLoanData((prev) => {
    const validName = loanData.name.length >= 2
    const validPhone = phoneRegex.test(loanData.phone)
    if (type === 'phone') {
      return {
        ...prev,
        phoneError: validPhone ? undefined : INPUT_ERRORS.PHONE_ERROR,
        isValid: validName && validPhone,
      }
    }
    return {
      ...prev,
      nameError: validName ? undefined : INPUT_ERRORS.NAME_ERROR,
      isValid: validName && validPhone,
    }
  })
  const changePhoneNumber = (phone: string) => setLoanData((prev) => ({ ...prev, phone }))
  const changeName = (name: string) => setLoanData((prev) => ({ ...prev, name }))
  const toggleShowLoanBanner = (showLoanBanner: boolean) => setLoanData((prev) => ({ ...prev, showLoanBanner }))
  const toggleShowSubmittedForm = (showSubmittedForm: boolean) => setLoanData((prev) => ({
    ...prev,
    showSubmittedForm,
  }))
  const setLoanError = (loanError: string | undefined) => setLoanData((prev) => ({...prev, loanError}))
  const onClear = () => setLoanData(defaultLoanState)

  return {
    loanData,
    validateData,
    changeName,
    changePhoneNumber,
    toggleShowLoanBanner,
    toggleShowSubmittedForm,
    setLoanError,
    onClear,
  }
}
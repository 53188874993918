import { Box } from '@material-ui/core'
import app_store_icon from 'assets/images/app_store.png'
import google_play_icon from 'assets/images/google_play.png'
import { FONTS } from 'common/enums/fonts'
import { CustomTypography } from 'components/Typography'
import { BOTTOM_BANNER } from '../const'
import { useStyles } from './styles'

type LeadConfirmationProps = {
  onAppStoreClicked?: () => void
  onGooglePlayClicked?: () => void
}

export const BottomBanner: React.FC<LeadConfirmationProps> = ({ onAppStoreClicked, onGooglePlayClicked }) => {
  const classes = useStyles()

  return (
    <Box className={classes.bottomBanner}>
      <Box className={classes.bottomBannerInner}>
        <Box className={classes.bottomBannerTexts}>
          <CustomTypography value={BOTTOM_BANNER.TITLE} type={FONTS.H4_BLACK_80} component="h4" />
          {/* <CustomTypography value={BOTTOM_BANNER.SUBTITLE} type={FONTS.H5_BLACK_60} component="h5" /> */}
        </Box>
        <Box className={classes.storeLogos}>
          <a
            href={'https://apps.apple.com/il/app/teaser/id1611370517?l=iw'}
            target="_blank"
            className={classes.storeLogo}
            rel="noreferrer"
            onClick={onAppStoreClicked}
          >
            <img className={classes.storeLogoImg} alt={'App Store'} src={app_store_icon} />
          </a>
          <a
            href={'https://play.google.com/store/apps/details?id=ltd.teaser.app'}
            target="_blank"
            className={classes.storeLogo}
            rel="noreferrer"
            onClick={onGooglePlayClicked}
          >
            <img className={classes.storeLogoImg} alt={'Google Play'} src={google_play_icon} />
          </a>
        </Box>
      </Box>
    </Box>
  )
}

import { TEXTS } from 'common/texts'
import moment from 'moment'
import { DATE_TYPES } from './const'

export const transformSignDate = (date: string, type: string): string => {
  const DEFAULT_LOCALE = 'he-IL'
  const DEFAULT_FORMAT = 'DD/MM/YYYY H:mm'
  const signDate = moment(date)
  switch (type) {
    case DATE_TYPES.PROPERTY:
      return TEXTS.AGREEMENT_SIGNED_ON + signDate.locale(DEFAULT_LOCALE).format(DEFAULT_FORMAT)
    case DATE_TYPES.EXCLUSIVITY:
      return signDate.locale(DEFAULT_LOCALE).format('DD/MM/YYYY')
    default:
      return signDate.locale(DEFAULT_LOCALE).format(DEFAULT_FORMAT)
  }
}

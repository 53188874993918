import { WINDOW_TYPES } from 'context/const'

type QueryParametersObject = {
  isPreview: boolean
  windowType: WINDOW_TYPES
}

export const getQueryParameters = (): QueryParametersObject => {
  const params = new URLSearchParams(window.location.search)

  return {
    isPreview: params.get('isPreview') === 'true',
    windowType: params.get('windowType') as WINDOW_TYPES,
  }
}

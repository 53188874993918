import { Box } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import lead_confirm from 'assets/images/lead_confirm.svg'
import { useStyles } from './styles'

type LeadConfirmationProps = {
  onClose: () => void
}

export const LeadConfirmation: React.FC<LeadConfirmationProps> = ({ onClose }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box onClick={onClose} className={classes.closeButton}>
        <Close />
      </Box>
      <Box className={classes.inner}>
        <img src={lead_confirm} alt="Lead Confirmation" className={classes.image} />
        <Box className={classes.text}>
          <h2 className={classes.title}>פנייתך נקלטה בהצלחה, מומחה משכנתה יחזור אליך בהקדם 😊</h2>
          {/* <CustomTypography
            component={FONTS_TAG_TYPE.H3}
            type={FONTS.H2_BLACK_80_BOLD}
            value={'פנייתך נקלטה בהצלחה, מומחה משכנתה יחזור אליך בהקדם 😊'}
          /> */}
        </Box>
      </Box>
    </Box>
  )
}

import { UserDataType } from './index'

export const defaultUserData: UserDataType = {
  signeeFullName: '',
  signeeNationalId: '',
  signeeAddress: '',
  signeeAgreedToTerms: '',
  signeeAgreedToPolicy: '',
  signatureUrl: '',
}

export const signatureUrl = 'signatureUrl'

import { Box } from '@material-ui/core'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import React from 'react'
import { AccountDetails } from '../AccountDetails'
import { CustomTypography } from '../Typography'
import { WhiteContainer } from '../containers/WhiteContainer'
import { useStyles } from './styles'

type ContactDetailsProps = {
  name: string
  title: string
  image: string
  description?: string
  children?: React.ReactNode
}

export const ContactDetails: React.FC<ContactDetailsProps> = ({ name, description, title, image, children }) => {
  const styles = useStyles()

  return (
    <WhiteContainer>
      <CustomTypography component={FONTS_TAG_TYPE.H3} type={FONTS.H3_BLACK} value={title} />

      <Box className={styles.box}>
        <Box className={styles.boxFlex}>
          <AccountDetails name={name} image={image} />
          {children}
        </Box>
      </Box>

      <Box className={styles.description}>
        {description && (
          <CustomTypography component={FONTS_TAG_TYPE.P} type={FONTS.P_Regular_Black_60} value={description} />
        )}
      </Box>
    </WhiteContainer>
  )
}

import { UserDataType } from './index'

export const dataValidator = (
  signature: string,
  { signeeAddress, signeeNationalId, signeeFullName, signeeAgreedToTerms, signeeAgreedToPolicy }: UserDataType,
): boolean =>
  !!signature.length &&
  !!signeeAddress &&
  !!signeeNationalId &&
  !!signeeFullName &&
  signeeAgreedToTerms === 'true' &&
  signeeAgreedToPolicy === 'true'

import { TeaserPropertyDetailsProps } from './components/TeaserPropertyDetails'
import { IPerson } from 'interfaces/teaser.interface'
import { AGREEMENT_TYPE } from './enums'

export type CommissionModel = {
  percentage: number
  sum: number
  months: number
  type: CommissionType
}

export enum CommissionType {
  SUM = 'SUM',
  PERCENTAGE = 'PERCENTAGE',
  MONTHS = 'MONTHS',
}

type AgreementType = {
  collaborationType: string
  exclusivity: boolean
  exclusivityEnd: string
  exclusivityStart: string
  fileUrl: any
  id: number
  title: string
  language: string
  notes: string
  otherCollaborationNotes: string
  signeeFullName: string
  signeeAddress: string
  signeeNationalId: string
  signeePhoneNumber: string
  signatureUrl: string
  signed: boolean
  signedAt: string
  type: AGREEMENT_TYPE
  rentCommission?: CommissionModel
  sellCommission?: CommissionModel
  content: string
  exclusivityTitle: string
  exclusivityContent: string
}

export interface IData extends TeaserPropertyDetailsProps {
  id: string
  agent: IPerson
  agreement: AgreementType
  showFullAddress: boolean
  showPropertyFullProfile: boolean
  isDraft: boolean
}

export interface ILoan {
  agreementId: number
  createdAt: string
  id: number
  name: string
  phoneNumber: string
}
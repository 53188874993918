import axios from 'axios'
import { TeaserPreviewType } from 'pages/TeaserScreen/helper'
import { IData, ILoan } from 'pages/TeaserScreen/types'
import { UserDataType } from 'pages/TeaserSign'
import { ErrorType } from 'types/error'
import { PREVIEW_TYPE } from '../pages/TeaserScreen/const'
import {
  BASE_URL,
  LEADS,
  LEAD_ERROR_CODE,
  LEAD_ERROR_TEXT,
  LOANS,
  LOAN_ERROR_CODE,
  LOAN_ERROR_TEXT,
  REQUEST_LOAN,
  SIGN,
  TEASERS,
  UPLOAD,
  VIEW,
} from './const'
import { ILead, ILoanRequest, webViewPreviewDataType } from './types'

export const instance = axios.create({
  baseURL: BASE_URL,
})

export const fetchTeaser = async (teaserId: string): Promise<ErrorType | IData> => {
  try {
    const response = await instance.get(`${TEASERS}/${teaserId}/${VIEW}`)
    return response.data.data
  } catch (error) {
    return error.response.data
  }
}

export const fetchTeaserPreview = async (
  webViewData: string | null,
  teaserId: string,
  previewType?: PREVIEW_TYPE,
): Promise<ErrorType | TeaserPreviewType> => {
  // For DEV only
  if (!webViewData) {
    try {
      const response = await instance.get(`${TEASERS}/${teaserId}/preview?preview=${previewType ?? PREVIEW_TYPE.FULL}`)
      return { data: response.data.data, preview: previewType ?? PREVIEW_TYPE.FULL }
    } catch (error) {
      return error.response.data
    }
  }

  const dataRequest: webViewPreviewDataType = JSON.parse(webViewData)
  previewType = previewType ?? dataRequest.preview === PREVIEW_TYPE.PARTIAL ? PREVIEW_TYPE.PARTIAL : PREVIEW_TYPE.FULL
  try {
    const response = await instance.get(`${TEASERS}/${teaserId}/preview?preview=${dataRequest.preview}`, {
      headers: {
        Authorization: `Bearer ${dataRequest.token}`,
        'Agency-Id': `${dataRequest.agencyId}`,
      },
    })
    return { data: response.data.data, preview: previewType }
  } catch (error) {
    return error.response.data
  }
}

export const uploadTeaserSignature = async (
  teaserId: string,
  signature: string,
  userData: UserDataType,
  signHelper: Function,
): Promise<undefined | ErrorType> => {
  try {
    const formData = new FormData()
    const blobSignature = await (await fetch(signature)).blob()
    const blobData = new Blob([blobSignature], { type: 'image/png' })
    formData.append('file', blobData)
    const response = await instance.post(`${TEASERS}/${teaserId}/${UPLOAD}`, formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    })
    signHelper(teaserId, { ...userData, signatureUrl: response.data.data.url })
  } catch (error) {
    return error.response.data
  }
}

export const signTeaser = async (teaserId: string, userData: UserDataType): Promise<ErrorType | IData> => {
  try {
    const { signeeAgreedToPolicy, ...reqBody } = userData
    const response = await instance.post(`${TEASERS}/${teaserId}/${SIGN}`, JSON.stringify(reqBody), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data.data
  } catch (error) {
    return error.response.data
  }
}

export const updateOrCreateLead = async (lead: ILead): Promise<string | ILoan> => {
  try {
    const response = await instance.post(LEADS, lead)
    return response.data.data
  } catch (error) {
    if (error.response.data.errorCode === LEAD_ERROR_CODE) {
      return LEAD_ERROR_TEXT
    }
    return error.response.data.error
  }
}

export const requestLoan = async (loan: ILoanRequest): Promise<string | ILoan> => {
  try {
    const response = await instance.post(`${LOANS}/${REQUEST_LOAN}`, loan)
    return response.data.data
  } catch (error) {
    if (error.response.data.errorCode === LOAN_ERROR_CODE) {
      return LOAN_ERROR_TEXT
    }
    return error.response.data.error
  }
}

import { Box } from '@material-ui/core'
import { COLORS } from 'common/enums/colors'
import { CustomInput } from 'components/Input'
import React, { ChangeEvent, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { signatureUrl } from '../../const'
import { UserDataType } from '../../index'
import { useStyles } from '../../styles'

type UserDataTypeDetails = {
  title?: string | ReactNode
  isNumeric?: boolean
  isBoolean?: boolean
}

const UserDataTypeInfo: Record<string, UserDataTypeDetails> = {
  signeeFullName: {},
  signeeAddress: {},
  signeeNationalId: { isNumeric: true },
  signeeAgreedToTerms: { isBoolean: true },
  signeeAgreedToPolicy: {
    isBoolean: true,
    title: (
      <Box style={{ fontWeight: 'normal', color: COLORS.GREY_DARK, fontSize: '12px' }}>
        אני מאשר/ת את{' '}
        <a
          href="https://www.teaser.ltd/תנאי-שימוש"
          target="_blank"
          style={{ color: COLORS.GREY_DARK }}
          rel="noreferrer"
        >
          התקנון ותנאי השימוש והפרטיות
        </a>
      </Box>
    ),
  },
  signatureUrl: {},
}

export type InputsBoxType = {
  inputs: UserDataType
  handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | MouseEvent) => void
  syncFieldError: Dispatch<SetStateAction<boolean>>
  termsCheckboxTitle: string
  hideTermsCheckbox: boolean
}

export const InputsBox: React.FC<InputsBoxType> = ({
  inputs: propInputs,
  handleChange,
  syncFieldError,
  termsCheckboxTitle,
  hideTermsCheckbox,
}) => {
  const [inputs, setInputs] = useState<[string, string | boolean][]>(Object.entries(propInputs))
  const styles = useStyles()

  useEffect(() => {
    propInputs && setInputs(Object.entries(propInputs).filter(([name]) => name !== signatureUrl))
  }, [propInputs])

  return (
    <>
      {inputs.map(([name, value]) => {
        let isHidden = false
        let title = name

        const inputInfo = UserDataTypeInfo[name] ?? {}

        if (name === 'signeeAgreedToTerms') {
          title = termsCheckboxTitle

          if (hideTermsCheckbox === true) {
            isHidden = true
          }
        }

        return (
          <Box key={name} className={isHidden ? styles.hiddenBox : styles.box}>
            <CustomInput
              name={name}
              isNumeric={inputInfo.isNumeric ?? false}
              isBoolean={inputInfo.isBoolean ?? false}
              title={inputInfo.title ?? title}
              value={value}
              handleChange={handleChange}
              syncFieldError={syncFieldError}
            />
          </Box>
        )
      })}
    </>
  )
}

import { Box } from '@material-ui/core'
import { TeaserInstanceDetails } from '../TeaserInstanceDetails/TeaserInstanceDetails'
import { ContractDetails } from 'pages/TeaserScreen/const'

type TeaserContractDetailsProps = {
  content?: string
  notes?: string
}

export const TeaserContractDetails: React.FC<TeaserContractDetailsProps> = ({ content, notes }) => {
  return (
    <Box>
      <TeaserInstanceDetails details={content ? [content] : ContractDetails} notes={notes} />
    </Box>
  )
}

import { makeStyles } from '@material-ui/core'
import { COLORS } from '../../common/enums/colors'

export const useStyles = makeStyles({
    pageWrapper: {
      padding: '0 24px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'hidden',
    },
    backButton: {
      width: '100%',
      height: 60,
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      width: 30,
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 20,
    },
    title: {
      color: '#FF335B',
      opacity: .9,
      fontWeight: 700,
      fontSize: 18,
    },
    list: {
      alignSelf: 'stretch',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 10,
      padding: 10,
      background: '#f5f4f4',
    },
    alert: {
      alignItems: 'center',
    },
    listItemWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      margin: 3,
    },
    listText: {
      color: COLORS.TEXT_COLOR,
      opacity: .6,
      fontWeight: 500,
    },
    subtitleText: {
      margin: '10px 0',
      fontSize: 16,
      opacity: .6,
      fontWeight: 400,
      color: COLORS.TEXT_COLOR
    },
  },
)
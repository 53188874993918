export const title = 'תודה רבה!'

export enum ROLES {
  CLIENT = 'CLIENT',
  COLLABORATION = 'COLLABORATION',
}

export const TOP_BANNER = {
  TITLE: 'טיזר פתרונות משלימים בנדל״ן',
  SUBTITLE: 'מגוון הטבות מחכות לכם באתר שלנו',
  BUTTON: 'לאתר',
}

export const BOTTOM_BANNER = {
  TITLE: 'להורדת האפליקציה',
  SUBTITLE: 'להורדה מחנויות האפליקציות',
}

export enum ROLES_TEXT {
  CLIENT_TEXT = 'ההסכם לתיווך הנכס נחתם בהצלחה. אפשר להוריד או לשלוח את ההסכם.',
  COLLABORATION_TEXT = 'ההסכם לתיווך בשותפות על הנכס נחתם בהצלחה. אפשר להוריד או לשלוח את ההסכם.',
  DEFAULT = 'ההסכם לתיווך לבעלות על נכס נחתם בהצלחה. אפשר להוריד או לשלוח את ההסכם.',
}

import React, { useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { DETAILS } from '../../enums'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { SignatureDetails } from 'components/SignatureDetails'
import { CustomTypography } from 'components/Typography'
import { useStyles } from './styles'
import { transformSignDate } from './helper'
import { DATE_TYPES } from './const'
import { TeaserFooter } from '../../../../components/TeaserFooter'

type TeaserSignatureDetailsProps = {
  signatureUrl: string
  signedAt: string
}

export const TeaserSignatureDetails: React.FC<TeaserSignatureDetailsProps> = ({ signatureUrl, signedAt }) => {
  const [signDate] = useState<string>(transformSignDate(signedAt, DATE_TYPES.DEFAULT))
  const styles = useStyles({ signatureUrl })

  return (
    <>
      <Box className={styles.boxSignature}>
        <Grid container item xs={12} alignItems="flex-end" justifyContent="space-between">
          <Grid container item xs={5} alignItems="flex-end" className={styles.imageWrapper}>
            <img src={signatureUrl} alt="Signature" className={styles.signatureImage} />
            <SignatureDetails title={DETAILS.USER_SIGNATURE} />
          </Grid>
          <Grid item xs={5} className={styles.dateWrapper}>
            <CustomTypography component={FONTS_TAG_TYPE.P} type={FONTS.P_Regular_Black_60} value={signDate} />
            <SignatureDetails title={DETAILS.DATE_SIGNATURE} />
          </Grid>
        </Grid>
      </Box>
      <TeaserFooter wasSigned={true} />
    </>
  )
}

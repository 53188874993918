import React from 'react'
import { Box } from '@material-ui/core'
import { UserCard } from 'components/cards/UserCard'
import { CustomDivider } from 'components/Divider'
import { mockCommission } from '../../../../TeaserScreen/const'
import { IData } from '../../../../TeaserScreen/types'
import { useStyles } from '../../../../TeaserScreen/styles'
import { TeaserOrderDetails } from 'pages/TeaserScreen/components/TeaserOrderDetails'

type TeaserDetailsType = {
  teaser: IData
  isExclusivity: boolean
}

export const TeaserAgentDetails: React.FC<TeaserDetailsType> = ({ teaser, isExclusivity }) => {
  const styles = useStyles()
  return (
    <>
      <Box className={styles.box}>
        <UserCard {...teaser.agent} />
      </Box>
      <CustomDivider />

      <TeaserOrderDetails
        sellCommission={teaser.agreement.sellCommission || mockCommission}
        rentCommission={teaser.agreement.rentCommission || mockCommission}
        agreementId={teaser.agreement.id}
        agreementType={teaser.agreement.type}
        agreementTitle={isExclusivity ? teaser.agreement.exclusivityTitle : teaser.agreement.title}
        exclusivity={teaser.agreement.exclusivity}
        signeeFullName={teaser.agreement.signeeFullName}
        signeeAddress={teaser.agreement.signeeAddress}
        signeeNationalId={teaser.agreement.signeeNationalId}
        signeePhoneNumber={teaser.agreement.signeePhoneNumber}
        isDraft={teaser.isDraft}
        isSigned={teaser.agreement.signed}
      />

      <CustomDivider />
    </>
  )
}

export enum SEPARATOR_TYPES {
  WEB = 'separator_web',
  MOBILE = 'separator_mobile',
  PC = 'separator_web',
}

export const contractTitle = 'הסכם הזמנת שירותי תיווך'
export const buttonText = 'תודה רבה'
export enum FORM_WRAPPER_TEXTS {
  SENTENCE_1 = 'נדבר בקרוב',
  SENTENCE_2 = 'ההודעה נשלחה בהצלחה',
}

export const imageSliderHeight = 350

import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    imageWrapper: {
      width: '100%',
      height: 100,
    },
    signatureImage: {
      width: '100%',
      height: 52,
      objectFit: 'contain',
    },
    dateWrapper: {
      textAlign: 'center',
    },
    boxSignature: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'flex-end',
      margin: '0 auto 30px',
    },
  }
})

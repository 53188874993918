export enum FONTS {
  H1_BLACK_60 = 'H1_BLACK_60',
  H2_BLACK = 'H2_BLACK',
  H2_BLACK_18 = 'H2_BLACK_18',
  H2_BLACK_16 = 'H2_BLACK_16',
  H2_BLACK_80 = 'H2_BLACK_80',
  H2_BLACK_80_BOLD = 'H2_BLACK_80_BOLD',
  H3_BLACK = 'H3_BLACK',
  H3_BLACK_60 = 'H3_BLACK_60',
  H3_PINK = 'H3_PINK',
  H3_PINK_400 = 'H3_PINK_400',
  H3_PINK_18 = 'H3_PINK_18',
  H4_BLACK = 'H4_BLACK',
  H4_BLACK_REGULAR = 'H4_BLACK_REGULAR',
  H4_BLACK_500 = 'H4_BLACK_500',
  H4_BLACK_80 = 'H4_BLACK_80',
  H4_PINK_60 = 'H4_PINK_60',
  H4_WHITE = 'H4_WHITE',
  H5_BLACK_60 = 'H5_BLACK_60',
  H5_PINK_60 = 'H5_PINK_60',
  H5_PINK_BOLD = 'H5_PINK_BOLD',
  H5_BLACK_80 = 'H5_BLACK_80',
  H6_BLACK_40 = 'H6_BLACK_40',
  P_Regular_Black_80 = 'P_Regular_Black_80',
  P_Regular_Black_60 = 'P_Regular_Black_60',
  P_HTML_Black_60 = 'P_HTML_Black_60',
  P_LIGHT_PINK_60 = 'P_LIGHT_PINK_60',
}

export enum FONTS_TAG_TYPE {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  P = 'p',
}

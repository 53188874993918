import { Box } from '@material-ui/core'
import { FONTS } from 'common/enums/fonts'
import { CustomTypography } from 'components/Typography'
import { WhiteContainer } from 'components/containers/WhiteContainer'
import { ReactNode } from 'react'
import { useStyles } from './styles'

type LinkCardProps = {
  title: string
  subtitle?: string
  icon?: ReactNode
  link?: string
  onClick?: () => void
}

export const LinkCard: React.FC<LinkCardProps> = ({ title, subtitle, icon, link, onClick }: LinkCardProps) => {
  const classes = useStyles()

  const handleOnClick = () => {
    if (link) {
      window.open(link, '_blank')
    }

    if (onClick) {
      onClick()
    }
  }

  return (
    <WhiteContainer fullWidth={true}>
      <Box className={classes.container} onClick={handleOnClick}>
        <Box>
          <CustomTypography value={title} type={FONTS.H4_BLACK_80} component="h4" />
          {subtitle && <CustomTypography value={subtitle} type={FONTS.H5_BLACK_60} component="h5" />}
        </Box>
        {icon}
      </Box>
    </WhiteContainer>
  )
}

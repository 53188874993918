import { Box, Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import { COLORS } from 'common/enums/colors'
import React, { ChangeEvent, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { INPUTS_ERRORS_TEXT, LABELS, addressRegex, idRegex, nameRegex } from './const'
import { getLabelText } from './helper'

type CustomInputProps = {
  name: string
  title: string | ReactNode
  value: string | boolean
  isNumeric: boolean
  isBoolean: boolean
  handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | MouseEvent) => void
  syncFieldError: Dispatch<SetStateAction<boolean>>
}

export const CustomInput: React.FC<CustomInputProps> = ({
  name,
  title,
  value,
  isNumeric,
  isBoolean,
  handleChange,
  syncFieldError,
}) => {
  const [label] = useState<string | ReactNode>(() => getLabelText(title))
  const [showError, setShowError] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>('')
  const [touched, setTouched] = useState<boolean>(false)

  useEffect(() => {
    syncFieldError(showError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showError])

  useEffect(() => {
    if (!touched || typeof value !== 'string') return
    else {
      if (label === LABELS.ID) {
        setShowError(!idRegex.test(value))
      } else if (value.length < 2) {
        setShowError(true)
      } else if (label === LABELS.FULLNAME) {
        setShowError(!nameRegex.test(value))
      } else if (label === LABELS.ADDRESS) {
        setShowError(!addressRegex.test(value))
      }
      setErrorText('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onInputLeave = () => {
    if (!touched) {
      setTouched(true)
    }
    if (typeof value !== 'string') {
      return
    }
    if (label === LABELS.ID && value.length !== 9) {
      setShowError(!idRegex.test(value))
      setErrorText(INPUTS_ERRORS_TEXT.teudatZeutLength)
    } else {
      if (value.length < 2) {
        setShowError(true)
      } else if (label === LABELS.FULLNAME) {
        setShowError(!nameRegex.test(value))
      } else if (label === LABELS.ADDRESS) {
        setShowError(!addressRegex.test(value))
      }
      setErrorText(INPUTS_ERRORS_TEXT.default)
    }
  }

  if (isBoolean) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            style={{ padding: '0px 9px' }}
            onChange={e => {
              handleChange({
                ...e,
                target: {
                  ...e.target,
                  name: e.target.name,
                  value: e.target.checked.toString(),
                },
              })
            }}
          />
        }
        label={<Box style={{ fontWeight: 'normal', color: COLORS.GREY_DARK, fontSize: '12px' }}>{title}</Box>}
      />
    )
  }

  return (
    <TextField
      fullWidth
      name={name}
      value={value}
      label={value ? '' : label}
      variant="filled"
      onChange={handleChange}
      error={showError}
      helperText={showError && errorText}
      onBlur={onInputLeave}
      onTouchEnd={() => setTouched(true)}
      inputProps={isNumeric ? { inputMode: 'numeric' } : {}}
      type={isNumeric ? 'number' : 'text'}
    >
      {title}
    </TextField>
  )
}

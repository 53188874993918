import React from 'react'
import { Box } from '@material-ui/core'
import { DETAILS } from 'pages/TeaserSign/enums'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { CustomTypography } from 'components/Typography'
import { useStyles } from '../../styles'
import { InputsBox, InputsBoxType } from '../InputsBox'

export const IdentifyingInfo: React.FC<InputsBoxType> = ({
  inputs,
  handleChange,
  syncFieldError,
  termsCheckboxTitle,
  hideTermsCheckbox,
}) => {
  const styles = useStyles()

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }

  return (
    <Box className={styles.box}>
      <Box className={styles.textWrapper}>
        <CustomTypography component={FONTS_TAG_TYPE.H3} type={FONTS.H3_BLACK} value={DETAILS.IDENTIFYING_INFO} />
      </Box>
      <form onSubmit={handleSubmit}>
        <InputsBox
          inputs={inputs}
          handleChange={handleChange}
          syncFieldError={syncFieldError}
          termsCheckboxTitle={termsCheckboxTitle}
          hideTermsCheckbox={hideTermsCheckbox}
        />
        <button type="submit" className="hidden"></button>
      </form>
    </Box>
  )
}

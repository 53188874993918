import { Box, Button } from '@material-ui/core'
import logo_black_circle from 'assets/images/logo_black_circle.png'
import { FONTS } from 'common/enums/fonts'
import { CustomTypography } from 'components/Typography'
import { TOP_BANNER } from '../const'
import { useStyles } from './styles'

type LeadConfirmationProps = {
  onSiteClicked?: () => void
}

export const TopBanner: React.FC<LeadConfirmationProps> = ({ onSiteClicked }) => {
  const classes = useStyles()

  return (
    <Box className={classes.topBanner}>
      <Box className={classes.topBannerInner}>
        <img className={classes.topBannerLogo} alt={'Teaser'} src={logo_black_circle} />
        <Box className={classes.topBannerTexts}>
          <CustomTypography value={TOP_BANNER.TITLE} type={FONTS.H4_BLACK_80} component="h4" />
          <CustomTypography value={TOP_BANNER.SUBTITLE} type={FONTS.H5_BLACK_60} component="h5" />
        </Box>
        <Button
          onClick={() => {
            if (onSiteClicked) {
              onSiteClicked()
            }

            window.open('https://www.teaser.ltd/services-4', '_blank')
          }}
          variant="contained"
          className={classes.topBannerButton}
        >
          {TOP_BANNER.BUTTON}
        </Button>
      </Box>
    </Box>
  )
}

export enum DETAILS {
  PROPERTY = 'פרטי הנכס',
  CONTRACT = 'פרטי החוזה',
  AGREEMENT = 'מספר הסכם:',
  BROKERAGE_FEE_RATE = 'תעריף דמי התיווך',
  CLIENT_NAME = 'שם הלקוח',
  CLIENT_PASSPORT_NUMBER = 'ת.ז',
  CLIENT_ADDRESS = 'כתובת',
  CLIENT_PHONE_NUMBER = 'טלפון',
  SUBSCRIBE = 'מעבר לחתימה',
  USER_SIGNATURE = 'חתימת הלקוח',
  DATE_SIGNATURE = 'תאריך ושעה',
}

export enum AGREEMENT_TYPE {
  CLIENT = 'CLIENT',
  COLLABORATION = 'COLLABORATION',
  PROPERTY_OWNER = 'PROPERTY_OWNER',
}

import { ILoanState } from './types'

export const phoneRegex =
  /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/

export const previousPage = -1

export enum LOAN_TEXTS {
  BACK_BUTTON = 'חזור',
  TITLE = 'מה ייעוץ משכנתאות נותן לך?',
  OPTION_1 = 'ניהול מו״מ מול הבנקים',
  OPTION_2 = 'תכנית פיננסית מותאמת אישית',
  OPTION_3 = 'חיסכון של עשרות אלפי ש״ח',
  OPTION_4 = 'קיצור תקופת המשכנתא',
  SENTENCE_1 = 'רוצה שיועץ משכנתאות יחזור אליך?',
  PROCEED_BUTTON = 'תחזרו אלי',
}

export enum LOAN_ADVANTAGES_LIST {
  OPTION_1 = 'ניהול מו״מ מול הבנקים',
  OPTION_2 = 'חיסכון של עשרות אלפי ש״ח',
  OPTION_3 = 'תמהיל משכנתא בהתאמה אישית',
  OPTION_4 = 'ליווי צמוד לכל אורך הדרך',
}

export enum INPUT_ERRORS {
  PHONE_ERROR = 'בבקשה הכנס מספר טלפון תקין',
  NAME_ERROR = 'נא להזין שם (מינימום 2 אותיות)',
}

export const defaultLoanState: ILoanState = {
  name: '',
  phone: '',
  isValid: false,
  showLoanBanner: true,
  showSubmittedForm: false,
}

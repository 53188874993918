import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '6px 3px',
      paddingInlineEnd: 0,
    },
    iconBox: {
      alignSelf: 'center',
    },
  }
})

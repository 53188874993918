import { makeStyles } from '@material-ui/core'
import { RADIUS } from 'common/enums/radius'

export const useStyles = makeStyles(() => {
  return {
    pullerContainer: {
      position: 'absolute',
      top: 5,
      height: 40,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2,
      background: 'white',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
    puller: {
      width: 75,
      height: 6,
      backgroundColor: '#D8D8D8',
      borderRadius: RADIUS.PULLER,
    },
  }
})

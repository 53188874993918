export const BASE_URL = process.env.REACT_APP_TEASER_API_URL
export const TEASERS = 'teasers'
export const PROPERTIES = 'properties'
export const UPLOAD = 'upload'
export const SIGN = 'sign'
export const VIEW = 'view'
export const LOANS = 'loans'
export const REQUEST_LOAN = 'request-loan'
export const LEADS = 'leads'
export const LOAN_ERROR_CODE = 1021
export const LOAN_ERROR_TEXT = 'קיימת בקשה למשכנתא למספר הטלפון שהוזן'
export const LEAD_ERROR_CODE = 1030
export const LEAD_ERROR_TEXT = 'קרתה שגיאה בשמירת הנתונים'

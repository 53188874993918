import { TeaserContext } from 'context/TeaserContext'
import { WINDOW_TYPES } from 'context/const'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchTeaser, fetchTeaserPreview } from 'services/teaser'
import { ErrorType } from 'types/error'
import { handleWebViewData } from 'utils/handleWebViewData'
import { getQueryParameters } from 'utils/queryParamsUtil'
import { TeaserScreenBody } from './components/TeaserScreenBody'
import { PREVIEW_TYPE } from './const'
import { TeaserPreviewType, checkType } from './helper'
import { IData } from './types'

export const TeaserScreen = () => {
  const navigate = useNavigate()
  const { gainTeaser, teaser } = useContext(TeaserContext)
  const { teaserId } = useParams()
  const [error, setError] = useState<string>('')
  const [previewType, setPreviewType] = useState<PREVIEW_TYPE>(PREVIEW_TYPE.UNSET)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [webViewData, setWebViewData] = useState<undefined | string>(undefined)

  const fetchHelper = async (isWebView: boolean) => {
    if (!teaserId) {
      return
    }

    const { windowType, isPreview } = getQueryParameters()

    setIsLoading(true) // show loading on API call

    let data: IData | ErrorType | TeaserPreviewType
    if (windowType === WINDOW_TYPES.SIGNED || windowType === WINDOW_TYPES.UNSIGNED || isPreview) {
      data = await fetchTeaserPreview('', teaserId)
    } else if (isWebView && webViewData) {
      data = await fetchTeaserPreview(webViewData, teaserId)
    } else {
      data = await fetchTeaser(teaserId)
    }

    if ('error' in data) {
      setError(data?.error)
    } else if (checkType(data)) {
      setPreviewType(data.preview)
      gainTeaser(data.data)
    } else {
      gainTeaser(data)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    handleWebViewData(webViewData, setWebViewData)
  }, [webViewData])

  useEffect(() => {
    if (teaser?.agreement.signed && teaser?.agreement.signatureUrl) {
      navigate(`/teasers/${teaser?.id}/sign/success`)
    }
  }, [navigate, teaser])

  useEffect(() => {
    if (!teaser) {
      // if there's already a teaser, dont fetch it again and show loader
      fetchHelper(!!webViewData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webViewData])

  return (
    <>
      <TeaserScreenBody
        error={error}
        previewType={previewType}
        webViewData={!!webViewData}
        isExclusivity={false}
        isLoading={isLoading}
      />
      {teaser?.agreement.exclusivity ? (
        <TeaserScreenBody
          error={error}
          previewType={previewType}
          webViewData={!!webViewData}
          isExclusivity={true}
          isLoading={isLoading}
        />
      ) : null}
    </>
  )
}

import { ReactNode } from 'react'
import { LABEL_TYPES, LABELS } from './const'

export const getLabelText = (title: string | ReactNode): string => {
  switch (title) {
    case LABEL_TYPES.FULLNAME:
      return LABELS.FULLNAME
    case LABEL_TYPES.ID:
      return LABELS.ID
    case LABEL_TYPES.ADDRESS:
      return LABELS.ADDRESS
    default:
      return ''
  }
}

import React, { useContext } from 'react'
import { Box, Typography } from '@material-ui/core'
import loan from 'assets/images/loan.svg'
import { useNavigate } from 'react-router-dom'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { LoanSubmitButton, NameInput, PhoneInput } from 'components/LoanInputs'
import { LOAN_ADVANTAGES_LIST, LOAN_TEXTS, previousPage } from './const'
import { useStyles } from './styles'
import { useLoan } from './hooks'
import { TeaserContext } from 'context/TeaserContext'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { requestLoanAgreement } from './helpers'
import listIcon from 'assets/images/selected.svg'

export const LoanScreen = () => {
  const {
    loanData: { phone, name, phoneError, nameError, isValid, loanError },
    changeName,
    changePhoneNumber,
    toggleShowSubmittedForm,
    toggleShowLoanBanner,
    onClear,
    validateData,
    setLoanError,
  } = useLoan()
  const styles = useStyles()
  const navigate = useNavigate()
  const { teaser } = useContext(TeaserContext)
  const agreementId = teaser?.agreement?.id

  const onSubmit = async () => {
    const response = await requestLoanAgreement({ agreementId: agreementId || 0, phoneNumber: phone, name })
    if (typeof response === 'string') {
      setLoanError(response)
      return
    }
    toggleShowSubmittedForm(true)
    toggleShowLoanBanner(false)
    navigate(previousPage)
  }

  const onFormClose = () => {
    onClear()
    navigate(previousPage)
  }

  const onClose = () => setLoanError(undefined)

  return (
    <Box className={styles.pageWrapper}>
      <Box className={styles.backButton} onClick={onFormClose}>
        <ChevronRight className={styles.icon} />
        <Typography>
          {LOAN_TEXTS.BACK_BUTTON}
        </Typography>
      </Box>
      <img src={loan} alt='image' />
      <Box className={styles.content}>
        <Typography className={styles.title}>
          {LOAN_TEXTS.TITLE}
        </Typography>
        <Box className={styles.list}>
          <Box>
            {Object.values(LOAN_ADVANTAGES_LIST).map((text) => <Box key={text} className={styles.listItemWrapper}>
              <img src={listIcon} alt='icon' />
              <Typography className={styles.listText}>
                {text}
              </Typography>
            </Box>)}
          </Box>
        </Box>
        <Typography className={styles.subtitleText} align='center'>
          {LOAN_TEXTS.SENTENCE_1}
        </Typography>
        <NameInput
          value={name}
          validateData={() => validateData('name')}
          onChange={(e) => changeName(e.target.value)}
          error={nameError}
        />
        <PhoneInput
          value={phone}
          validateData={() => validateData('phone')}
          onChange={(e) => changePhoneNumber(e.target.value)}
          error={phoneError}
        />
        <LoanSubmitButton isActive={isValid && Boolean(agreementId)} onClick={onSubmit} />
        <Snackbar open={Boolean(loanError)} autoHideDuration={1500} onClose={onClose}>
          <Alert className={styles.alert} onClose={onClose} severity='error'>
            {loanError}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  )
}
export enum DETAILS {
  IDENTIFYING_INFO = 'פרטים מזהים',
  SIGNATURE_PART = 'אישור',
  SIGNATURE = 'חתימה',
  SIGNATURE_RESET = 'איפוס חתימה',
  CLIENT_NAME = 'שם הלקוח',
  BROKER_NAME = 'שם המתווך',
  PASSPORT_NUMBER = 'תעודת זהות',
  ADDRESS = 'כתובת מגורים',
  COMPANY_NAME = 'שם המשרד',
  COMPANY_NUMBER = 'תעודת זהות / ח.פ',
}

export enum INPUT_NAMES {
  FULL_NAME = 'fullname',
  ADDRESS = 'clientAddress',
  COMPANY_NAME = 'companyName',
  SIGNATURE = 'signatureUrl',
  CLIENT_NUMBER = 'clientNationalId',
  COMPANY_NUMBER = 'companyNumber',
}

import { Box, Grid, Typography } from '@material-ui/core'
import mailbox from 'assets/images/mailbox.svg'
import { DESCRIPTION } from 'common/enums/texts'
import { PropertyCharacteristics } from 'components/AmenitiesDetails'
import { AttachmentsDetails } from 'components/AttachmentsDetails'
import { Map } from 'components/Map'
import { PropertyDescription } from 'components/PropertyDescription'
import { Puller } from 'components/Puller'
import { TaxesDetails } from 'components/TaxesDetails'
import { CustomButton } from 'components/buttons/Button'
import { PaginationButton } from 'components/buttons/PaginationButton'
import { WhiteContainer } from 'components/containers/WhiteContainer'
import { ApartmentDescription } from 'components/screensPropertyDetails/ApartamentDescription'
import { HeaderDetails } from 'components/screensPropertyDetails/HeaderDetails'
import { TeaserContext } from 'context/TeaserContext'
import { IPerson, PropertyInterface } from 'interfaces/teaser.interface'
import React, { useContext, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useLoan } from '../../../LoanScreen/hooks'
import { DATE_TYPES } from '../../../TeaserScreen/components/TeaserSignatureDetails/const'
import { transformSignDate } from '../../../TeaserScreen/components/TeaserSignatureDetails/helper'
import { ContactsContainerDetails } from '../ContactsContainerDetails'
import { FORM_WRAPPER_TEXTS, buttonText } from './const'
import { gainDeviceType } from './helper'
import './style.scss'
import { useStyles } from './styles'

type ContainerDetailsBrokerProps = {
  estateObject: PropertyInterface
  isWebView: boolean
  isDraft: boolean
}

export const ContainerDetailsBroker: React.FC<ContainerDetailsBrokerProps> = ({ isWebView, isDraft, estateObject }) => {
  const {
    loanData: { showSubmittedForm },
    onClear,
  } = useLoan()
  const emptyValue = '-'
  const { teaser, device } = useContext(TeaserContext)
  const styles = useStyles()
  const { id } = useParams()
  const { address, description, amenities, airDirections, files, agent, houseCommitteePrice, propertyTaxPrice } =
    estateObject

  const exclusivityDate = useMemo<string>(
    () => transformSignDate(teaser?.agreement.exclusivityEnd || '', DATE_TYPES.EXCLUSIVITY),
    [teaser],
  )
  const agentData = useMemo<IPerson>(() => (teaser?.agent ? teaser.agent : agent), [agent, teaser])
  const separatorStyles = useMemo<string>(() => gainDeviceType(isWebView, device), [isWebView, device])
  const teaserDate = useRef<string>(transformSignDate(teaser?.agreement.signedAt || '', DATE_TYPES.PROPERTY))
  const fullAddress = useMemo<string>(() => {
    if (address.description && address.description.length > 0) {
      return address.description
    }

    let fullAddressStr = `${address.street} ${address.number}, ${address.city}`
    if (address.apartment) {
      fullAddressStr = `${address.street} ${address.number}/${address.apartment}, ${address.city}`
    }

    return fullAddressStr
  }, [address])

  return (
    <Box
      className={`${isDraft ? styles.mainBoxForDraft : styles.mainBox} ${
        showSubmittedForm && styles.loanConfirmationBox
      }`}
    >
      <Puller />
      <Grid container item xs={12} direction="column" spacing={3} className="drawerWrapper">
        {showSubmittedForm ? (
          <Grid className={styles.submittedFormWrapper} item xs={12}>
            <img height={279} src={mailbox} alt="mailbox image" />
            <Typography className={styles.submittedFormTitle}>
              {FORM_WRAPPER_TEXTS.SENTENCE_2}
              <br />
              {FORM_WRAPPER_TEXTS.SENTENCE_1}
            </Typography>
            <CustomButton title={buttonText} onSign={onClear} />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <HeaderDetails
                isExclusively={!!teaser?.agreement.exclusivity}
                date={exclusivityDate}
                address={fullAddress}
              />
            </Grid>

            <Grid item xs={12}>
              <ApartmentDescription estateObject={estateObject} />
            </Grid>

            <Grid item xs={12}>
              <WhiteContainer>
                <PropertyDescription title={DESCRIPTION.PROPERTY_DESCRIPTION} description={description ?? emptyValue} />
              </WhiteContainer>
            </Grid>

            <Grid item xs={12}>
              <Map showFullAddress={true} address={address} />
            </Grid>

            <Grid item xs={12}>
              <PropertyCharacteristics amenities={amenities} airDirections={airDirections ?? [emptyValue]} />
            </Grid>

            <Grid item xs={12}>
              <WhiteContainer>
                <TaxesDetails houseCommitteePrice={houseCommitteePrice} propertyTaxPrice={propertyTaxPrice} />
              </WhiteContainer>
            </Grid>

            {/* <Grid item xs={12}>
          <AdditionalPayments details={paymentsDetails ?? []} />
        </Grid> */}

            <Grid item xs={12}>
              <AttachmentsDetails attachments={files ?? []} />
            </Grid>

            <Grid item xs={12}>
              <ContactsContainerDetails
                name={agentData.fullName}
                image={agentData.image?.imageUrl || ''}
                description={agentData.description}
                phone={agentData.phoneNumber}
              />
            </Grid>

            {/* {teaser?.agreement.signedAt && (
              <Grid item xs={12}>
                <ContractInfo link={teaser.agreement.fileUrl || ''} date={teaserDate.current} text={contractTitle} />
              </Grid>
            )} */}

            <div className={separatorStyles} />
            {!isWebView && teaser && teaser.properties.length > 1 && id && <PaginationButton teaser={teaser} id={id} />}
          </>
        )}
      </Grid>
    </Box>
  )
}

export const dateFormattingDetails = {
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
}

export const exclusivityDateFormattingDetails = {
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
}

export const enum DATE_TYPES {
  PROPERTY = 'PROPERTY',
  EXCLUSIVITY = 'EXCLUSIVITY',
  DEFAULT = 'DEFAULT',
}

import { makeStyles } from '@material-ui/core'
import { COLORS } from 'common/enums/colors'

export const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
  generalStyles: {
    '& fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 24px',
    },
  },
  phoneInputWrapper: {
    width: '100%',
    position: 'relative',
  },
  phoneInput: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    outline: 'none',
  },
  customLabel: {
    transition: '.3s linear all',
    '& label': {
      top: 8,
      left: -10,
      color: 'grey',
      transformOrigin: 'right',
    },
    '& .MuiInputLabel-shrink': {
      color: COLORS.PINK,
      fontSize: 14,
      top: 20,
    },
  },
  additionalLabelSpacings: {
    '& label': {
      left: -12,
    },
  },
})
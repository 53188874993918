import { Typography } from '@material-ui/core'
import parse from 'html-react-parser'
import { ElementType, ReactNode } from 'react'
import { useStyles } from './styles'
import { FontsType } from './types'

type CustomTypographyProps = {
  value: String | number | ReactNode
  component: ElementType
  type: FontsType
  isHtml?: boolean
}

export const CustomTypography: React.FC<CustomTypographyProps> = ({ value, type, component, isHtml }) => {
  const styles = useStyles()

  return (
    <Typography component={component} className={styles[type]}>
      {isHtml ? (value ? parse(value.toString()) : value) : value}
    </Typography>
  )
}

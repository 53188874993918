import { ICONS } from 'common/enums/icons'

export const icons: any = [{ id: 1, title: ICONS.PHONE }]

export const errorText = 'Unknown error. Please try again'

export enum LOAN_BANNER {
  TITLE = "בואו נדבר על משכנתא.",
  SENTENCE_1 = "מעוניין בשיחת ייעוץ ללא עלות?",
  PROCEED_BUTTON = "לפרטים"
}

export const LOAN_BANNER_HEIGHT = 90
import { Box } from '@material-ui/core'
import { CustomButton } from 'components/buttons/Button'
import ErrorToast from 'components/ToastNotifications/ErrorToast'
import { TeaserContext } from 'context/TeaserContext'
import React, { ChangeEvent, Dispatch, SetStateAction, useContext } from 'react'
import { DETAILS } from '../../enums'
import { UserDataType } from '../../index'
import { useStyles } from '../../styles'
import { IdentifyingInfo } from '../IdentifyingInfo'
import { SignatureBox } from '../SignatureBox'

type TeaserSignContentType = {
  userData: UserDataType
  handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | MouseEvent) => void
  syncSignature: Dispatch<SetStateAction<string>>
  isActive: boolean
  onSign: () => Promise<void>
  signError: string
  syncFieldError: Dispatch<SetStateAction<boolean>>
  termsCheckboxTitle: string
  hideTermsCheckbox: boolean
}

export const TeaserSignContent: React.FC<TeaserSignContentType> = ({
  userData,
  handleChange,
  onSign,
  signError,
  isActive,
  syncSignature,
  syncFieldError,
  termsCheckboxTitle,
  hideTermsCheckbox,
}) => {
  const { device } = useContext(TeaserContext)
  const styles = useStyles()

  return (
    <Box className={styles.container}>
      <Box>
        <IdentifyingInfo
          inputs={userData}
          handleChange={handleChange}
          syncFieldError={syncFieldError}
          termsCheckboxTitle={termsCheckboxTitle}
          hideTermsCheckbox={hideTermsCheckbox}
        />
        <SignatureBox syncSignature={syncSignature} />
      </Box>
      <Box>
        <CustomButton title={DETAILS.SIGNATURE_PART} onSign={onSign} isActive={isActive} />
      </Box>
      {signError && <ErrorToast errorText={signError} />}
    </Box>
  )
}

import React from 'react'
import { TeaserContractDetails } from '../../TeaserContractDetails'
import { IData } from '../../../types'

type TeaserAgreementsType = {
  teaser: IData
  isExclusivity: boolean
}

export const TeaserAgreements: React.FC<TeaserAgreementsType> = ({ teaser, isExclusivity }) => {
  return (
    <>
      <TeaserContractDetails
        content={isExclusivity ? teaser.agreement.exclusivityContent : teaser.agreement.content}
        notes={teaser.agreement.notes}
      />

      {/* <TeaserBrokerageDetails
        sellCommission={teaser.agreement.sellCommission}
        rentCommission={teaser.agreement.rentCommission}
      /> */}
    </>
  )
}

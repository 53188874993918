import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => {
  const BANNER_BG_COLOR = '#EFEFEF'
  const BANNER_BORDER = '1px solid #C0C0C0'

  return {
    banner: {
      width: '100%',
      // height: 203,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
    },
    bottomBanner: {
      zIndex: 99,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      padding: '10px 16px',
      background: BANNER_BG_COLOR,
      borderTop: BANNER_BORDER,
    },
    bottomBannerInner: {
      maxWidth: '360px',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      alignItems: 'center',
      margin: '0 auto',
    },
    bottomBannerTexts: {
      textAlign: 'center',
    },
    storeLogos: {
      width: '100%',
      display: 'flex',
      gap: '8px',
    },
    storeLogo: {},
    storeLogoImg: {
      width: '100%',
      display: 'inline-block',
    },
  }
})

import { Box } from '@material-ui/core'
import { FONTS, FONTS_TAG_TYPE } from 'common/enums/fonts'
import { CustomTypography } from 'components/Typography'
import { TeaserContext } from 'context/TeaserContext'
import { WINDOW_TYPES } from 'context/const'
import { PropertyInterface } from 'interfaces/teaser.interface'
import React, { useContext } from 'react'
import { getQueryParameters } from 'utils/queryParamsUtil'
import { PREVIEW_TYPE } from '../../const'
import { AGREEMENT_TYPE, DETAILS } from '../../enums'
import { useStyles } from '../../styles'
import { TeaserPropertyCard } from '../TeaserPropertyCard'
import { AccordionHelper } from './AccordionHelper'

export type TeaserPropertyDetailsProps = {
  isSigned: boolean
  showFullAddress: boolean
  showPropertyFullProfile: boolean
  properties: PropertyInterface[]
  teaserId: string
  previewType: PREVIEW_TYPE
  agreementType: AGREEMENT_TYPE
}

export const TeaserPropertyDetails: React.FC<TeaserPropertyDetailsProps> = ({
  properties,
  teaserId,
  showFullAddress,
  showPropertyFullProfile,
  isSigned,
  previewType,
  agreementType,
}) => {
  const { teaser } = useContext(TeaserContext)
  const styles = useStyles()
  const { windowType } = getQueryParameters()
  const isSingleProperty = properties.length === 1

  if (!showFullAddress && windowType === WINDOW_TYPES.SIGNED) {
    showFullAddress = true
  }

  return (
    <Box key={`${teaserId}_${windowType}`}>
      <Box className={styles.box}>
        <CustomTypography component={FONTS_TAG_TYPE.H3} type={FONTS.H3_BLACK} value={DETAILS.PROPERTY} />
      </Box>
      {teaser?.agreement.type !== AGREEMENT_TYPE.PROPERTY_OWNER ? (
        <Box>
          {properties.map((property: PropertyInterface) => (
            <AccordionHelper
              key={property.id}
              property={property}
              teaserId={teaserId}
              isSigned={isSigned}
              showFullAddress={showFullAddress}
              showPropertyFullProfile={showPropertyFullProfile}
              previewType={previewType}
              isExpanded={isSingleProperty}
              agreementType={agreementType}
            />
          ))}
        </Box>
      ) : (
        <Box>
          {properties.map((property: PropertyInterface) => (
            <Box className={styles.propertyCardWrapper} key={property.id}>
              <Box className={styles.propertyCard}>
                <TeaserPropertyCard property={property} showFullAddress={showFullAddress}></TeaserPropertyCard>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

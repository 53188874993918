import { StaticScreenWrapper } from 'common/HOCs/StaticScreenWrapper'
import { Preloader } from 'components/Preloader/Preloader'
import { Wrapper } from 'components/containers/Wrapper'
import { TeaserContext } from 'context/TeaserContext'
import { AGREEMENT_TYPE } from 'pages/TeaserScreen/enums'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { signTeaser, uploadTeaserSignature } from 'services/teaser'
import { TeaserSignContent } from './components/TeaserSignContent/TeaserSignContent'
import { defaultUserData } from './const'
import { dataValidator } from './helper'

export type UserDataType = {
  signeeFullName: string
  signeeAddress: string
  signeeNationalId: string
  signeeAgreedToTerms: string
  signeeAgreedToPolicy: string
  signatureUrl: string
}

export const TeaserSign = () => {
  const { teaser, gainTeaser } = useContext(TeaserContext)
  const { teaserId } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [userData, setUserData] = useState<UserDataType>(defaultUserData)
  const [signature, setSignature] = useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [next, setNext] = useState<boolean | undefined>(undefined)
  const [fieldError, setFieldError] = useState<boolean>(false)
  const [termsCheckboxTitle, setTermsCheckboxTitle] = useState<string>('')
  const [hideTermsCheckbox, setHideTermsCheckbox] = useState<boolean>(true)
  const [signError, setSignError] = useState<string>('')

  const handleError = (response: string): void => {
    setUserData(defaultUserData)
    setSignature('')
    setSignError(response)
    setIsLoading(false)
    navigate(`/teasers/${teaserId}/sign`)
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | MouseEvent): void => {
    const userInput = e.target as HTMLInputElement
    setUserData(prevState => {
      return { ...prevState, [userInput.name]: userInput.value }
    })
  }

  const teaserSignHelper = async (teaserId: string, data: UserDataType): Promise<void> => {
    const response = await signTeaser(teaserId, data)
    if ('error' in response) {
      handleError(response.error)
      return
    }
    gainTeaser(response)
    setNext(true)
    setIsLoading(false)
  }

  const onSign = async (): Promise<void> => {
    setIsActive(false)
    if (isValid && teaserId) {
      setIsLoading(true)
      const response = await uploadTeaserSignature(teaserId, signature, userData, teaserSignHelper)
      if (response?.error) {
        handleError(response.error)
        return
      }
    }
  }

  useEffect(() => {
    if (teaser?.agreement.signed || !teaser?.agreement) {
      navigate(`/teasers/${teaserId}`)
    }

    if (teaser?.agreement?.type === AGREEMENT_TYPE.PROPERTY_OWNER && teaser?.agreement.exclusivity) {
      setTermsCheckboxTitle('אני מאשר/ת בחתימתי את שני ההסכמים המצורפים')
      setHideTermsCheckbox(false)
    } else if (teaser?.agreement?.type !== AGREEMENT_TYPE.PROPERTY_OWNER && !teaser?.showFullAddress) {
      setTermsCheckboxTitle(`אני מאשר/ת כי כתובת הנכס המלאה תימסר
ותתווסף להסכם לאחר החתימה`)
      setHideTermsCheckbox(false)
    } else {
      setUserData({ ...defaultUserData, signeeAgreedToTerms: 'true' })
    }
  }, [navigate, teaser, teaserId])

  useEffect(() => {
    if (typeof next === 'undefined') return

    if (next) {
      if (teaser?.showPropertyFullProfile && teaser.agreement.type !== AGREEMENT_TYPE.PROPERTY_OWNER) {
        navigate(`/teasers/${teaserId}/properties/${teaser.properties[0].id}`)
      } else {
        navigate(`/teasers/${teaserId}/sign/success`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [next])

  useEffect(() => {
    setIsActive(isValid && !fieldError)
  }, [isValid, fieldError])

  useEffect(() => {
    setIsValid(dataValidator(signature, userData))
  }, [signature, userData])

  return (
    <StaticScreenWrapper>
      <Wrapper>
        {isLoading ? (
          <Preloader />
        ) : (
          <TeaserSignContent
            userData={userData}
            handleChange={handleChange}
            syncSignature={setSignature}
            isActive={isActive}
            onSign={onSign}
            signError={signError}
            syncFieldError={setFieldError}
            termsCheckboxTitle={termsCheckboxTitle}
            hideTermsCheckbox={hideTermsCheckbox}
          />
        )}
      </Wrapper>
    </StaticScreenWrapper>
  )
}
